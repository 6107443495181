import Swal from "sweetalert2";
import store from "../../../store";

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const checkAllResponses = response =>
  delay(1000).then(() => {
    let countError = 0;
    let countSucess = 0;
    let msgServ = "";
    let msgDep = "";
    let msgEnd = "";
    let msgTel = "";
    let msgE = "";
    let htmlErroDep = "";
    let htmlErroEnd = "";
    let htmlErroTel = "";
    let htmlErroE = "";
    let erroServidor = false;
    let erroMsgServidor = "";
    console.log(response);
    Object.keys(response).map(key => {
      if (key === "responseServidor") {
        if (response[key].status >= 400) {
          countError = countError + 1;
          erroMsgServidor = response[key].body.mensagem;
          //htmlErroDep = `<p style="text-align: center;"><span style="color: #ff0000; font-size: 10px;"><strong>${erroMsg}</strong></span></p>`;
          erroServidor = true;
        }
        if (response[key].status === 200) {
          if (response[key].body.mensagem.includes("não")) {
            countError = countError + 1;
          } else {
            countSucess = countSucess + 1;
          }
          msgServ = `<tr>
                    <td style="width: 67px; text-align: left;">Servidor</td>
                    <td style="width: 67px; text-align: center;"><span style="color: #008000;"><strong>${countSucess}</strong></span></td>
                    <td style="width: 67px; text-align: center;"><span style="color: #ff0000;"><strong>${countError}</strong></span></td>
                    </tr>`;
        }
      }
      if (key === "responseDependente") {
        countError = 0;
        countSucess = 0;
        let erroMsg = "";
        console.log(response[key]);
        response[key].map(res => {
          console.log(res);
          if (res.status >= 400) {
            countError = countError + 1;
            erroMsg = res.body.mensagem;
            htmlErroDep = `<p style="text-align: center;"><span style="color: #ff0000; font-size: 10px;"><strong>${erroMsg}</strong></span></p>`;
          }
          if (res.status === 200) {
            if (res.body.mensagem.includes("não")) {
              countError = countError + 1;
            } else {
              countSucess = countSucess + 1;
            }
          }
          msgDep = `<tr>
                    <td style="width: 67px; text-align: left;">Dependentes</td>
                    <td style="width: 67px; text-align: center;"><span style="color: #008000;"><strong>${countSucess}</strong></span></td>
                    <td style="width: 67px; text-align: center;"><span style="color: #ff0000;"><strong>${countError}</strong></span></td>
                    </tr>`;
        });
      }
      if (key === "responseEndereco") {
        countError = 0;
        countSucess = 0;
        let erroMsg = "";
        console.log(response[key]);
        response[key].map(res => {
          if (res.status >= 400) {
            countError = countError + 1;
            erroMsg = res.body.mensagem;
            htmlErroEnd = `<p style="text-align: center;"><span style="color: #ff0000; font-size: 10px;"><strong>${erroMsg}</strong></span></p>`;
          }
          if (res.status === 200) {
            if (res.body.mensagem.includes("não")) {
              countError = countError + 1;
            } else {
              countSucess = countSucess + 1;
            }
          }
          msgEnd = `<tr>
                    <td style="width: 67px; text-align: left;">Endere&ccedil;os</td>
                    <td style="width: 67px; text-align: center;"><span style="color: #008000;"><strong>${countSucess}</strong></span></td>
                    <td style="width: 67px; text-align: center;"><span style="color: #ff0000;"><strong>${countError}</strong></span></td>
                    </tr>`;
        });
      }
      if (key === "responseTelefone") {
        countError = 0;
        countSucess = 0;
        let erroMsg = "";
        console.log(response[key]);
        response[key].map(res => {
          if (res.status >= 400) {
            countError = countError + 1;
            erroMsg = res.body.mensagem;
            htmlErroTel = `<p style="text-align: center;"><span style="color: #ff0000; font-size: 10px;"><strong>${erroMsg}</strong></span></p>`;
          }
          if (res.status === 200) {
            if (res.body.mensagem.includes("não")) {
              countError = countError + 1;
            } else {
              countSucess = countSucess + 1;
            }
          }
          msgTel = `<tr>
                    <td style="width: 67px; text-align: left;">Telefones</td>
                    <td style="width: 67px; text-align: center;"><span style="color: #008000;"><strong>${countSucess}</strong></span></td>
                    <td style="width: 67px; text-align: center;"><span style="color: #ff0000;"><strong>${countError}</strong></span></td>
                    </tr>`;
        });
      }
      if (key === "responseEmail") {
        countError = 0;
        countSucess = 0;
        let erroMsg = "";
        console.log(response[key]);
        response[key].map(res => {
          if (res.status >= 400) {
            countError = countError + 1;
            erroMsg = res.body.mensagem;
            htmlErroE = `<p style="text-align: center;"><span style="color: #ff0000; font-size: 10px;"><strong>${erroMsg}</strong></span></p>`;
          }
          if (res.status === 200) {
            if (res.body.mensagem.includes("não")) {
              countError = countError + 1;
            } else {
              countSucess = countSucess + 1;
            }
          }
          msgE = `<tr>
                    <td style="width: 67px; text-align: left;">Emails</td>
                    <td style="width: 67px; text-align: center;"><span style="color: #008000;"><strong>${countSucess}</strong></span></td>
                    <td style="width: 67px; text-align: center;"><span style="color: #ff0000;"><strong>${countError}</strong></span></td>
                    </tr>`;
        });
      }
    });

    const html =
      '<table style="height: 155px; margin-left: auto; margin-right: auto;" width="223"><tbody><tr><td style="width: 67px;">&nbsp;</td>' +
      '<td style="width: 67px; text-align: center;"><strong><span style="color: #008000;">Sucesso</span></strong></td>' +
      '<td style="width: 67px; text-align: center;"><strong><span style="color: #ff0000;">Erro</span></strong></td></tr>' +
      msgServ +
      msgDep +
      msgEnd +
      msgTel +
      msgE +
      "</tbody></table>" +
      '<p style="text-align: center;"><strong><span style="color: #3366ff; font-size: 10px;">Para&nbsp;qualquer&nbsp;altera&ccedil;&atilde;o&nbsp;agora&nbsp;v&aacute;&nbsp;em&nbsp;Detalhes&nbsp;do&nbsp;servidor&nbsp;criado ou exclua este servidor se algo vir a dar errado' +
      "</span></strong></p>" +
      htmlErroDep +
      htmlErroEnd +
      htmlErroTel +
      htmlErroE;

    if (response.responseServidor) {
      if (erroServidor) {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: erroMsgServidor,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
      } else {
        Swal.fire({
          //title: "Sucesso",
          icon: "success",
          html: html,
          showCancelButton: false,
          customClass: {
            confirmButton: "md-button md-success btn-fill"
          },
          buttonsStyling: false,
          confirmButtonText: "Ok"
        });
        store.dispatch("dependente/ActionSetDEPENDENTE", "", { root: true });
        store.dispatch("endereco/ActionSetENDERECO", "", { root: true });
        store.dispatch("telefone/ActionSetTELEFONE", "", { root: true });
        store.dispatch("email/ActionSetEMAIL", "", { root: true });
        store.dispatch("servidor_publico/ActionSetSERVIDOR_PUBLICO", "", {
          root: true
        });
        window._Vue.$router.push({ name: "Servidores" }).catch(err => {});
      }
    } else {
      Swal.fire({
        //title: "Sucesso",
        icon: "warning",
        text: "OPSSS!! Algo deu Errado.",
        showCancelButton: false,
        customClass: {
          confirmButton: "md-button md-success btn-fill"
        },
        buttonsStyling: false,
        confirmButtonText: "Ok"
      });
    }
  });
